/**
 * A wrapper for the Content API v1.
 */

const CONTENT_API_URL = import.meta.env.CONTENT_API_V1_URL;
const CONTENT_API_AUTH_TOKEN = import.meta.env.CONTENT_API_AUTH_TOKEN;

export class ContentV1 {
  private contentApiUrl: string;
  private contentApiAuthToken: string | null;
  private logRequests: boolean;

  constructor(
    contentApiUrl?: string,
    contentApiAuthToken?: string,
    logRequests: boolean = true,
  ) {
    this.contentApiUrl = contentApiUrl || CONTENT_API_URL;
    this.contentApiAuthToken = contentApiAuthToken || CONTENT_API_AUTH_TOKEN;
    this.logRequests = logRequests;
  }

  /** Fetch data from the Content API v1. */
  async fetchContent<T>(path: string): Promise<T> {
    const headers: HeadersInit = {
      referer: "https://wildatheart.org",
    };

    if (this.contentApiAuthToken) {
      headers.Authorization = this.contentApiAuthToken;
    }

    if (this.logRequests) {
      console.log(
        `content-v1.ts fetchContent from: ${this.contentApiUrl}${path}`,
      );
    }

    const response = await fetch(`${this.contentApiUrl}${path}`, {
      headers,
    });
    const responseJson = await response.json();
    return responseJson as T;
  }

  /**
   * Get the latest daily reading.
   * @return The daily reading.
   */
  async getRecentDailyReading(limit: number = 1) {
    const res = await this.fetchContent<
      App.DataTypeObjects.API.V1.Content.DailyReadingResponse[]
    >(`/daily-reading/recent?limit=${limit}`);
    return res;
  }

  /**
   * Get all daily readings.
   * @return All daily readings.
   */
  async getAllDailyReadings() {
    const res =
      await this.fetchContent<
        App.DataTypeObjects.API.V1.Content.DailyReadingResponse[]
      >(`/daily-reading`);
    return res;
  }

  async getRedirectForDailyReading(slug: string) {
    const res = await this.fetchContent<{
      previous_slug: string;
      current_slug: string;
    }>(`/daily-reading/redirect/${slug}`);

    if (res.current_slug) {
      return `/daily-readings/${res.current_slug}`;
    }

    return null;
  }

  /**
   * Get a media item entry by it's slug.
   * @param slug The slug of the media item.
   * @return The media item.
   */
  async getMediaItemBySlug(slug: string) {
    const res = await this.fetchContent<App.DataTypeObjects.MediaItemData>(
      `/media/${slug}`,
    );
    return res;
  }

  /**
   * Get a media item entry or series by slug or uri.
   * @param slugOrUri The slug or uri of the media item or series.
   * @return The media item or series.
   */
  async getMediaItemOrSeriesBySlug(slugOrUri: string) {
    const res = await this.fetchContent<
      | App.DataTypeObjects.Statamic.Blueprints.CustomSeries
      | App.DataTypeObjects.Statamic.Blueprints.SubsplashMediaSeries
      | App.DataTypeObjects.API.V1.ErrorResponse
    >(`/media-or-series/${slugOrUri}`);
    return res;
  }

  /**
   * Get a page entry by it's slug.
   * @param slug The slug of the page.
   * @return The page.
   */
  async getPageBySlug(slug: string) {
    const res = await this.fetchContent<App.DataTypeObjects.PageData>(
      `/page/${slug}`,
    );
    return res;
  }
  /**
   * Get all prayers entries.
   * @param slug The slug of the prayers entry.
   * @return The prayers entry.
   */
  async getAllPrayersEntries() {
    const res =
      await this.fetchContent<
        App.DataTypeObjects.API.V1.Content.Prayers.PrayersShowResponse[]
      >(`/prayers`);
    return res;
  }

  /**
   * Get a prayers entry by it's slug.
   * @param slug The slug of the prayers entry.
   * @return The prayers entry.
   */
  async getPrayersEntryBySlug(slug: string) {
    const res =
      await this.fetchContent<App.DataTypeObjects.API.V1.Content.Prayers.PrayersShowResponse>(
        `/prayers/${slug}`,
      );
    return res;
  }

  /**
   * Get Visible Alerts.
   * @return An array of alerts.
   */
  async getAlerts() {
    const res =
      await this.fetchContent<
        App.DataTypeObjects.API.V1.Content.AlertResponse[]
      >(`/alerts`);
    return res;
  }

  /**
   * Get blogs by uri
   */
  async getBlogsByUri(uri: string) {
    const res = await this.fetchContent<
      | App.DataTypeObjects.API.V1.Content.BlogResponse[]
      | App.DataTypeObjects.API.V1.Content.BlogResponse
    >(`/blogs/${uri}`);
    return res;
  }

  /**
   * Get blogs
   */
  async getBlogs() {
    const res =
      await this.fetchContent<
        App.DataTypeObjects.API.V1.Content.BlogResponse[]
      >(`/blogs`);
    return res;
  }

  async searchContent(options: {
    author_slug?: string;
    collection?: "blog" | "media_item";
    limit?: number;
    page?: number;
  }) {
    let queryString = "?";
    if (options.author_slug) {
      queryString += `&author_slug=${options.author_slug}`;
    }
    if (options.collection) {
      queryString += `&collection=${options.collection}`;
    }
    if (options.limit) {
      queryString += `&limit=${options.limit}`;
    }
    if (options.page) {
      queryString += `&page=${options.page}`;
    }
    queryString.replace("?&", "?");
    const res = await this.fetchContent<
      App.DataTypeObjects.API.V1.Content.BlogResponse[]
    >(`/search${queryString}`);
    return res;
  }
}

/**
 * Convenience type exports
 */
export type AlertResponse = App.DataTypeObjects.API.V1.Content.AlertResponse;
export type BlogResponse = App.DataTypeObjects.API.V1.Content.BlogResponse;
/**
 * From generated.d.ts in Local project.
 * Eventually we should move this to a shared package.
 */
declare namespace App.DataTypeObjects {
  export type Attributes = {
    title: string;
    number: number;
    season: number;
    status: string;
    published_at: string;
    duration: number | null;
    explicit: boolean;
    keywords: string;
    alternate_url: string;
    media_url: string;
    image_url: string | null;
    video_url: string;
    author: string;
    summary: string | null;
    description: string;
    slug: string;
    created_at: string;
    updated_at: string;
    formatted_published_at: string;
    duration_in_mmss: string;
    share_url: string;
    transcript_url: string | null;
    transcripts: Array<any>;
    formatted_summary: string;
    formatted_description: string;
    embed_html: string;
    embed_html_dark: string;
    audio_processing: boolean;
    type: string;
    email_notifications: string | null;
  };
  export type Blueprint = {
    title: string;
    handle: string;
  };
  export type ButtonProps = {
    text: string | null;
    href: string | null;
    variant: string | null;
    leftIcon: any | null;
    rightIcon: any | null;
    openInModal: boolean | null;
    openInNewTab: boolean | null;
  };
  export type CardVariant = {
    name: string;
    value: string;
  };
  export type ContentPreview = {
    id: any;
    title: string | null;
    description: string | null;
    uri: string | null;
    featured_image_src: string | null;
    published_at: string | null;
    collection: any | null;
    blueprint: any | null;
    related_content: Array<any> | null;
  };
  export type Entry = {
    test_prop: string;
  };
  export type File = {
    url: string;
    filename: string;
    basename: string;
    extension: string;
    size: string;
    mime_type: string;
    size_bytes: number;
  };
  export type GenericBlock = {
    id: string;
    title: string;
    type: string;
  };
  export type HeroTitleBlock = {
    type: string;
    hero_title: Array<App.DataTypeObjects.TextLineBlock>;
  };
  export type ImageData = {
    square: string | null;
    wide: string | null;
    banner: string | null;
    vertical: string | null;
  };
  export type MediaItemData = {
    id: string;
    blocks: Array<
      App.DataTypeObjects.SlideBlock | App.DataTypeObjects.GenericBlock
    > | null;
    images: App.DataTypeObjects.ImageData | null;
    locale: string;
    parent: Array<App.DataTypeObjects.ParentData> | null;
    private: boolean;
    published: boolean;
    seo_description: string | null;
    seo_image: Array<any> | null;
    seo_title: string | null;
    slug: string;
    status: string;
    title: string;
    uri: string;
    url: string;
    blueprint: App.DataTypeObjects.Blueprint;
  };
  export type MediaItemsSlide = {
    type: any;
    slides: { [key: number]: App.DataTypeObjects.SlideBlock };
  };
  export type PageData = {
    id: string;
    blocks: Array<
      | App.DataTypeObjects.SlideBlock
      | App.DataTypeObjects.GenericBlock
      | App.DataTypeObjects.HeroTitleBlock
    > | null;
    images: App.DataTypeObjects.ImageData;
    locale: string;
    parent: Array<App.DataTypeObjects.ParentData> | null;
    private: boolean;
    published: boolean;
    seo_description: string | null;
    seo_image: Array<any> | null;
    seo_title: string | null;
    slug: string;
    status: string;
    title: string;
    uri: string | null;
    url: string | null;
    blueprint: App.DataTypeObjects.Blueprint;
  };
  export type ParentData = {
    id: string;
    title: string;
    url: string;
    permalink: string;
    api_url: string;
  };
  export type PlayableFile = {
    playtime: string;
    duration_seconds: number;
    url: string;
    filename: string;
    basename: string;
    extension: string;
    size: string;
    mime_type: string;
    size_bytes: number;
  };
  export type Relationships = {
    show: App.DataTypeObjects.ShowData;
  };
  export type Show = {
    id: string;
    type: string;
  };
  export type ShowData = {
    data: App.DataTypeObjects.Show;
  };
  export type SlideBlock = {
    id: any;
    type: any;
    title: string | null;
    subtitle: string | null;
    featured_image_src: string | null;
    tag: string | null;
    leader: string | null;
    description: string | null;
    trim_description_bool: boolean | null;
    details: string | null;
    variant: App.DataTypeObjects.CardVariant | null;
    left_cta: App.DataTypeObjects.ButtonProps | null;
    right_cta: App.DataTypeObjects.ButtonProps | null;
    href: string | null;
    is_coming_soon: boolean | null;
    date: string | null;
  };
  export type StatamicValue = {
    value: string;
    label: string;
    key: string;
  };
  export type TextLineBlock = {
    type: string;
    text: string;
    text_style: App.DataTypeObjects.StatamicValue;
    id: string;
  };
  export type TransistorPodcastEpisode = {
    id: string;
    type: string;
    attributes: App.DataTypeObjects.Attributes;
    relationships: App.DataTypeObjects.Relationships;
  };
}
declare namespace App.DataTypeObjects.API.V1 {
  export type EntryResponse = {
    id: string;
    title: string;
    private: boolean;
    published: boolean;
    status: string;
    slug: string;
    uri: string | null;
    url: string | null;
    blueprint: App.DataTypeObjects.Blueprint;
    locale: string;
  };
  export type ErrorResponse = {
    error: string;
  };
  export type RedirectResponse = {
    redirect: string;
    code: string;
  };
}
declare namespace App.DataTypeObjects.API.V1.Content {
  export type AlertResponse = {
    id: string;
    title: string;
    private: boolean;
    published: boolean;
    status: string;
    blueprint: App.DataTypeObjects.Blueprint;
    locale: string;
    content: string;
    start_date: string;
    end_date: string;
    link_text: string;
    link_url: string;
  };
  export type BlogResponse = {
    published_date: string;
    author_name: string | null;
    summary: string;
    content: Array<any> | string | null;
    featured_image_src: Array<any> | string | null;
    api_url: string;
    id: string;
    title: string;
    private: boolean;
    published: boolean;
    status: string;
    slug: string;
    uri: string | null;
    url: string | null;
    blueprint: App.DataTypeObjects.Blueprint;
    locale: string;
  };
  export type DailyReadingResponse = {
    transistor_episode: App.DataTypeObjects.TransistorPodcastEpisode;
    current_episode_slug: string | null;
  };
}
declare namespace App.DataTypeObjects.API.V1.Content.Prayers {
  export type PrayersShowResponse = {
    content: string;
    summary: string;
    drupal_url: string;
    mp3: App.DataTypeObjects.PlayableFile | null;
    pdf: App.DataTypeObjects.File | null;
    id: string;
    title: string;
    private: boolean;
    published: boolean;
    status: string;
    slug: string;
    uri: string | null;
    url: string | null;
    blueprint: App.DataTypeObjects.Blueprint;
    locale: string;
  };
}
declare namespace App.DataTypeObjects.Statamic.Blueprints {
  export type CustomSeries = {
    id: string;
    blocks: Array<any | any> | null;
    images: App.DataTypeObjects.ImageData | null;
    locale: string;
    parent: Array<App.DataTypeObjects.ParentData> | null;
    private: boolean;
    published: boolean;
    seo_description: string | null;
    seo_image: Array<any> | null;
    seo_title: string | null;
    slug: string;
    status: string;
    title: string;
    uri: string;
    url: string;
    blueprint: App.DataTypeObjects.Blueprint;
  };
  export type SubsplashMediaSeries = {
    id: string;
    title: string;
    slug: string;
    uri: string;
    url: string;
    blueprint: App.DataTypeObjects.Blueprint;
    subtitle: string;
    subsplash_data: Array<any>;
  };
}
