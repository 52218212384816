<script lang="ts">
  "Registration for our April 10th, 2025 Wild at Heart Retreat in Colorado is open.";
  export let CONTENT_API_V1_URL: string = "";
  export let CONTENT_API_AUTH_TOKEN: string = "";

  import { onMount } from "svelte";
  import { ContentV1 } from "@api/content-v1";
  import { slide } from "svelte/transition";

  import type { AlertResponse } from "@api/content-v1";
  let alerts: AlertResponse[] = [];

  onMount(async () => {
    const content = new ContentV1(
      CONTENT_API_V1_URL,
      CONTENT_API_AUTH_TOKEN,
      false,
    );
    alerts = await content.getAlerts();
    let currentPath = "";
    if (typeof window !== "undefined") {
      currentPath = window.location.pathname;
    }

    // Filter out alerts where current path matches alert link path
    alerts = alerts.filter((alert) => {
      try {
        console.log({ alertPath: alert.link_url });
        // Handle both full URLs and pathnames
        const alertPath = alert.link_url.startsWith("http")
          ? new URL(alert.link_url).pathname
          : alert.link_url;
        return alertPath !== currentPath;
      } catch (e) {
        // If link_url is invalid, keep the alert
        return true;
      }
    });
  });
</script>

{#if alerts.length > 0}
  <section
    transition:slide={{ duration: 300, axis: "y" }}
    class="p-3 w-full text-center text-white bg-gradient-to-r from-p500/25 to-p500"
  >
    {@html alerts[0].content?.replace(/<\/?p>/g, "")}
    <a class="underline" href={alerts[0].link_url}>{alerts[0].link_text}</a>
  </section>
{/if}
